import type { Component, Structure, BreakpointRange } from '@wix/thunderbolt-becky-types'
import type { MegaStore } from '@wix/thunderbolt-catharsis'
import type {
	MediaQueryToSelectorObj,
	ComponentCss,
	SelectorObj,
	CompIdTo,
	DomAppliers,
	ComponentCssFeatures,
	SingleComponentCss,
} from '../types'
import _ from 'lodash'
import { variablesDomApplier } from '../variables/variablesDomApplier'
import { responsiveLayoutDomApplier } from '../responsiveLayout/responsiveLayoutDomApplier'
import { getTemplateRepeaterIdSelector, getRegularIdSelector } from './selectorsUtils'

const EMPTY_ARRAY = [] as const
const THE_DEFAULT_BREAKPOINT = { id: 'default', mediaQuery: 'default' } as const

const DOM_APPLIERS: DomAppliers = {
	responsiveLayout: responsiveLayoutDomApplier,
	variables: variablesDomApplier,
}

export const getChildrenAndSlots = (comp: Component): Array<string> => [
	...(comp.components || EMPTY_ARRAY),
	...(comp.slots ? Object.values(comp.slots) : EMPTY_ARRAY),
]

export const toMediaQuery = (item: BreakpointRange) => {
	const min = item.min ? ` and (min-width: ${item.min}px)` : ''
	const max = item.max ? ` and (max-width: ${item.max}px)` : ''
	return `@media screen${min}${max}`
}

const selectorObjToCss = (selectorObj: SelectorObj) =>
	Object.entries(selectorObj)
		.flatMap(
			([selector, css]) =>
				`${selector}{${Object.entries(css)
					.map(([key, value]) => `${key}:${value};`)
					.join('')}}`
		)
		.join('')

export const getComponentsCss = (
	megaStore: MegaStore,
	structure: Structure,
	pageCompId: string
): CompIdTo<SingleComponentCss> => {
	const cssStore = megaStore.getChildStore('componentCss')

	const traverseComponents = (
		comp: Component,
		isInRepeater: boolean,
		ancestorBreakpointsOrder: ComponentCss['breakpointsOrder'],
		acc: CompIdTo<SingleComponentCss>
	) => {
		const compId = comp.id
		const { breakpointsOrder = ancestorBreakpointsOrder, ...compCss } = cssStore.getById<ComponentCss>(compId)

		acc[compId] = getSingleComponentCss(compId, isInRepeater, breakpointsOrder, compCss)

		getChildrenAndSlots(comp).forEach((childId) =>
			traverseComponents(
				structure[childId],
				isInRepeater || comp.type === 'RepeaterContainer',
				breakpointsOrder,
				acc
			)
		)
		return acc
	}

	return traverseComponents(structure[pageCompId], false, undefined, {})
}

export const getSingleComponentCss = (
	compId: string,
	isInRepeater: boolean,
	breakpointsOrder: ComponentCss['breakpointsOrder'],
	compCss: ComponentCssFeatures
): SingleComponentCss => {
	const idSelector = isInRepeater ? getTemplateRepeaterIdSelector(compId) : getRegularIdSelector(compId)

	const breakpoints = [
		THE_DEFAULT_BREAKPOINT,
		...(breakpointsOrder?.values || EMPTY_ARRAY).map((item) => ({ id: item.id, mediaQuery: toMediaQuery(item) })),
	]

	return _.mapValues(compCss, (css, key) =>
		breakpoints.reduce<MediaQueryToSelectorObj>((acc, { id, mediaQuery }) => {
			if (!css) {
				return acc
			}
			// @ts-expect-error
			const selectorObj = DOM_APPLIERS[key](compId, idSelector, id, css)
			acc[mediaQuery] = acc[mediaQuery] ? _.merge({}, acc[mediaQuery], selectorObj) : selectorObj
			return acc
		}, {})
	)
}

const stringifyMediaQueryToSelectorObj = (mediaQueryToSelectorObj: MediaQueryToSelectorObj) => {
	let css = ''
	for (const mediaQuery in mediaQueryToSelectorObj) {
		const selectorObj = mediaQueryToSelectorObj[mediaQuery]
		const cssString = selectorObjToCss(selectorObj)
		css += mediaQuery === 'default' ? cssString : `${mediaQuery}{${cssString}}`
	}
	return css
}

export const stringifySingleComponentCss = (singleCompCss: SingleComponentCss) =>
	Object.values(singleCompCss).map(stringifyMediaQueryToSelectorObj).join('')

export const stringifyAllComponentsCss = (compsToCss: CompIdTo<SingleComponentCss>) => {
	let css = ''
	for (const compId in compsToCss) {
		const mediaQueriesToSelectorObjForComp = compsToCss[compId]
		css += stringifySingleComponentCss(mediaQueriesToSelectorObjForComp)
	}
	return css
}
